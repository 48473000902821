import React from 'react';
import DashboardHandler from "actions/DashboardHandler";
import { useAuth } from "auth/useAuth";
import { TableRef } from "common/Table";
import { IFilter, IHighlight, IList } from "common/interfaces";
import { useFlag } from "hooks/useFlag";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { DASHBOARD_SECTIONS, emptyIList, POPUPS } from "utils/constants";
import { formatAmount, formatAPICurrency, formatAPIDate } from "utils/formatters";
import Observer, { EVENTS } from "classes/Observer";
import useMerchant from "hooks/useMerchant";
import usePartner from "hooks/usePartner";
import LocalStorage from "classes/LocalStorage";
import { LeaderboardType } from "common/types";
import { MerchantHandler } from "actions/MerchantHandler";
import { PartnerHandler } from "actions/PartnerHandler";
import { useBusinessModel } from "hooks/useBusinessModel";
import { Link } from "common";
import Analytics, { ITracking } from 'classes/Analytics';

export function useDashboard() {
  const user = useAuth()?.user;
  const { merchantSlug, partnerSlug } = useParams();
  const merchant = useMerchant();
  const partner = usePartner();
  const selectedLocation = useAppSelector(state => state.app.selectedLocation);
  const location = user?.user_type === "WHITELABEL" || user?.user_type === "PARTNER" ? null : selectedLocation?.id;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [financeActivity, setFinanceActivity] = useState(null);
  const [applicationActivity, setApplicationActivity] = useState(null);
  const [merchantsActivity, setMerchantsActivity] = useState(null);
  const [activityFunnel, setActivityFunnel] = useState(null);
  const [merchantsStats, setMerchantsStats] = useState<any>();
  const [isAmount, setIsAmount] = useState<boolean>(true);
  const [filter, setFilter] = useState<IFilter>({
    date_from: moment('01/01/2023', 'MM/DD/YYYY').toDate(),
    date_to: moment().toDate(),
    location: location || null
  });
  const enablePartner = useFlag("partners");
  const [merchantsActivityHasError, setMerchantsActivityHasError] = useState<boolean>(false);
  const [applicationsActivityHasError, setApplicationsActivityHasError] = useState<boolean>(false);
  const [merchantsStatsHasError, setMerchantsStatsHasError] = useState<boolean>(false);
  const [financeActivityHasError, setFinanceActivityHasError] = useState<boolean>(false);
  const [activityFunnelHasError, setActivityFunnelHasError] = useState<boolean>(false);
  const [whitelabelOrPartnerMetrics, setWhitelabelOrPartnerMetrics] = useState<any>(null);
  const selectedBusinessModel = useBusinessModel();

  const [usersTableHasError, setUsersTableHasError] = useState<boolean>(false);
  const [locationsTableHasError, setLocationsTableHasError] = useState<boolean>(false);
  const [merchantsTableHasError, setMerchantsTableHasError] = useState<boolean>(false);
  const [partnersTableHasError, setPartnersTableHasError] = useState<boolean>(false);

  const leaderBoardTypeLS = (user?.user_type === "MERCHANT" ? LocalStorage.get<string>("merchant_leaderboard_type") : LocalStorage.get("wl_leaderboard_type")) as LeaderboardType;
  const [leaderboardType, setLeaderboardType] = useState<LeaderboardType>(leaderBoardTypeLS || "locations");

  const locationLeaderboardTableRef = useRef<TableRef>(null);

  const businessModelUpdated = Observer.useObserver(EVENTS.BUSINESS_MODEL_UPDATED);

  const merchantFilter = useAppSelector(state => state.app.tableFilters.merchant);
  const partnerFilter = useAppSelector(state => state.app.tableFilters.partner);

  const [tableRows, setTableRows] = useState<Array<any>>([]);
  const tableRef = useRef<TableRef>(null);

  const [leaderboardBtnOptions, setLeaderboardBtnOptions] = useState<Array<any>>([]);

  useEffect(() => {
    if (businessModelUpdated > 1) {
      window.location.reload();
    }
  }, [businessModelUpdated]);

  useEffect(() => {
    if (!user?.user_type) {
      navigate('/login');
    } else {
      updateAll();
    }

    if (user?.user_type === "WHITELABEL" || user?.user_type === "PARTNER") {
      setLeaderboardBtnOptions([{ label: "Users", value: "users" }, { label: "Locations", value: "locations" }, { label: "Merchants", value: "merchants" }, { label: "Partners", value: "partners" }]);
    } else if (user?.user_type === "MERCHANT") {
      setLeaderboardBtnOptions([{ label: "Users", value: "users" }, { label: "Locations", value: "locations" }]);
      setLeaderboardType("locations");
    }
  }, [user]);

  useEffect(() => {
    setFilter({ ...filter, location: location });
  }, [location]);

  useEffect(() => {
    updateAll();
  }, [filter]);

  const updateCard = async (handlerFunction: any, setSuccess: any, setError: any, sectionType?: string, mode?: "amount" | "count") => {
    try {
      const response = sectionType ? await handlerFunction(sectionType, filter, mode) : await handlerFunction(filter, mode);
      setSuccess(response);
      setError(false);
    } catch (error) {
      setError(true);
    } finally {
      return Promise.resolve();
    }
  };

  const updateActivityFunnel = async () => {
    return updateCard(DashboardHandler.getInfo, setActivityFunnel, setActivityFunnelHasError, DASHBOARD_SECTIONS.ACTIVITY_FUNNEL);
  }

  const updateApplicationActivity = async () => {
    return updateCard(DashboardHandler.getInfo, setApplicationActivity, setApplicationsActivityHasError, DASHBOARD_SECTIONS.APPLICATION_ACTIVITY, isAmount ? "amount" : "count");
  }

  const updateFinanceActivity = async () => {
    return updateCard(DashboardHandler.getInfo, setFinanceActivity, setFinanceActivityHasError, DASHBOARD_SECTIONS.FINANCE_ACTIVITY);
  }

  const updateMerchantStats = async () => {
    return updateCard(DashboardHandler.getMerchantsStats, setMerchantsStats, setMerchantsStatsHasError);
  }

  const updateMerchantsActivity = async () => {
    return updateCard(DashboardHandler.getMerchantsActivity, setMerchantsActivity, setMerchantsActivityHasError);
  }

  const updateAll = async () => {
    updateActivityFunnel();
    updateApplicationActivity();
    updateFinanceActivity();
    if (user?.user_type === "WHITELABEL" || user?.user_type === "PARTNER") {
      updateMerchantStats();
      updateMerchantsActivity();
    }
  }

  const parseLocationLeaderboard = async (next: string): Promise<IList> => {
    try {
      let list = await DashboardHandler.getLocationLeaderboard(next, filter);
      list.results = list.results.map(item => {
        return {
          name: item.name,
          requested_count: formatAmount(item.requested_count),
          requested_total: formatAPICurrency(item.requested_total),
          funded_total: formatAPICurrency(item.funded_total),
        }
      });

      setLocationsTableHasError(false);
      return Promise.resolve(list);
    } catch (error) {
      setLocationsTableHasError(true);
      return Promise.resolve(emptyIList);
    }
  }

  const getMerchants = async (next: string): Promise<IList> => {
    try {
      let response = await DashboardHandler.getDashboardMerchants(next, merchantFilter, false);
      response.results = response.results.map(merchant => {
        return {
          ...merchant,
          name: <Link
            id={`merchant_${merchant?.slug}`}
            href={`/viewMerchant/${merchant?.slug}/accountInformation`}
            className="fontTextM"
            onClick={() => {
              Analytics.track({ experience: "portal", screen: "merchants", object: "merchant_record", action: "selected" } as ITracking, { merchant_id: merchant?.id });
            }}
            linkText={merchant?.name} />,
          created_at: formatAPIDate(merchant?.created_at),
          last_application_at: formatAPIDate(merchant?.last_application_at),
          requested_total: formatAPICurrency(merchant?.requested_total),
          funded_total: formatAPICurrency(merchant?.funded_total),
          highlight: { highlighted: merchant?.status === "Pending", property: "name" } as IHighlight,
        }
      });
      setMerchantsTableHasError(false);
      return Promise.resolve(response);
    } catch (error) {
      setMerchantsTableHasError(true);
      return Promise.resolve(emptyIList);
    }
  }

  const getPartners = async (next: string): Promise<IList> => {
    try {

      let response = await DashboardHandler.getDashboardPartners(next, partnerFilter, false);
      response.results = response.results.map(partner => {
        return {
          ...partner,
          name: <Link
            id={`partner_${partner?.slug}`}
            href={`/viewPartner/${partner?.slug}/accountInformation`}
            className="fontTextM"
            onClick={() => {
              Analytics.track({ experience: "portal", screen: "partners", object: "partner_record", action: "selected" } as ITracking, { partner_id: partner?.id });
            }}
            linkText={partner?.name} />,
          created_at: formatAPIDate(partner?.created_at),
          last_referral_at: formatAPIDate(partner?.last_referral_at, true),
          requested_total: formatAPICurrency(partner?.referral_requested_amount),
          funded_total: formatAPICurrency(partner?.referral_funded_amount),
          // Partners can't be pending at the moment
          // highlight: { highlighted: partner?.status === "Pending", property: "name" } as IHighlight,
          // status: (partner?.status === "Pending") ?
          //   <Button className={styles.merchantPendingStatus} onClick={() => { handlePendingStatus(partner) }} label={partner?.status} id={partner?.id} styleOverride={{ height: "40px", padding: "0.5rem", maxWidth: "100px" }}>{partner?.status}</Button>
          //   : partner?.status,
        }
      });
      setPartnersTableHasError(false);
      return Promise.resolve(response);
    } catch {
      setPartnersTableHasError(true);
      return Promise.resolve(emptyIList);
    }
  }

  const getListUsers = async (next: string): Promise<IList> => {
    try {
      let list = await DashboardHandler.getUserLeaderboard(next, filter);
      list.results = list.results.map(item => {
        return {
          ...item,
          merchants_added_count: formatAmount(item.merchants_added_count),
          funded_total: formatAPICurrency(item.funded_total),
        }
      })
      setUsersTableHasError(false);
      return list;
    } catch {
      setUsersTableHasError(true);
      return Promise.resolve(emptyIList);
    }
  }

  useEffect(() => {
    if (user?.user_type === "WHITELABEL") {
      getWLRates();
    }
    // prepared for the new partner view
    // else if (user?.user_type === "PARTNER") {
    //   getPartnerRates();
    // }
  }, [filter]);

  useEffect(() => {
    if (user?.user_type === "MERCHANT" && merchant?.slug) {
      MerchantHandler().getStats(merchant?.slug, false, filter);
    }
  }, [merchant?.slug, filter, selectedBusinessModel]);

  const getWLRates = async () => {
    const response = await DashboardHandler.getWhitelabelRates(filter);
    setWhitelabelOrPartnerMetrics(response);
  }

  // prepared for the new partner view
  // const getPartnerRates = async () => {
  //   if (partner?.slug || partnerSlug) {
  //     const response = await DashboardHandler.getPartnerRates(partner?.slug || partnerSlug, filter);
  //     setWhitelabelOrPartnerMetrics(response);
  //   }
  // }

  useEffect(() => {
    updateApplicationActivity();
  }, [isAmount]);

  useEffect(() => {
    tableRef?.current?.reloadData();
  }, [filter]);

  return {
    user, dispatch, enablePartner, filter, setFilter, isAmount, merchantsActivityHasError,
    updateMerchantsActivity, merchantsActivity, applicationActivity, applicationsActivityHasError, updateApplicationActivity,
    financeActivity, financeActivityHasError, updateFinanceActivity, merchantsStatsHasError, merchantsStats,
    updateActivityFunnel, activityFunnelHasError, activityFunnel, parseLocationLeaderboard,
    locationLeaderboardTableRef, setIsAmount, merchantFilter, tableRows, setTableRows, tableRef,
    leaderboardType, setLeaderboardType, getListUsers, partnerFilter, leaderboardBtnOptions,
    whitelabelOrPartnerMetrics, merchant, getMerchants, getPartners,
    usersTableHasError, locationsTableHasError, merchantsTableHasError, partnersTableHasError
  }
}