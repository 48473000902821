import { IUser } from "common/interfaces";
import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStorage } from "./useAuthStorage";
import LocalStorage from "classes/LocalStorage";
import appActions from "reducers/AppReducer";
import { useAppDispatch } from "reducers/Hooks";
import Analytics, { ITracking } from "classes/Analytics";
import EnableCache from "classes/EnableCache";
import { FeatureFlagContext } from "hooks/useFlag";
import { useTokenRefresh } from "hooks/useTokenRefresh";

interface IAuthContext {
  setUser: React.Dispatch<React.SetStateAction<IUser | null>>;
  user: IUser | null;
  login: (data: IUser, origin: string) => Promise<void>;
  logout: (navigateToLogin: boolean) => void;
}

const AuthContext = createContext<IAuthContext | null>(null);

export const AuthProvider = ({ children }) => {
  const { clearFlags } = useContext(FeatureFlagContext);
  const [user, setUser] = useAuthStorage(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useTokenRefresh();

  // call this function when you want to authenticate the user
  const login = async (data: IUser, origin: string) => {
    EnableCache.clear();
    setUser(data);
    navigate(origin);
  };

  // call this function to sign out logged in user
  const logout = (navigateToLogin: boolean) => {
    EnableCache.clear();
    setUser(null);
    dispatch(appActions.clearLocation());
    Analytics.setIdentity(null);
    LocalStorage.clear(true);
    clearFlags();
    Analytics.track({ experience: "portal", screen: "[varies]", object: "logout", action: "successful" } as ITracking, { screen: window.location.pathname });

    if (navigateToLogin) {
      navigate("/login");
    }
  };

  const value = useMemo(
    () => ({
      setUser,
      user,
      login,
      logout
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
