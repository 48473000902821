import AuthHandler from "actions/AuthHandler";
import LocalStorage from "classes/LocalStorage";
import Observer, { EVENTS } from "classes/Observer";
import { useEffect, useState } from "react";
import { getTokenExpirationTime } from "utils/helpers";

export const useTokenRefresh = () => {
    const [accessToken, setAccessToken] = useState(LocalStorage.get("access_token") || "");
    const [refreshInterval, setRefreshInterval] = useState(null);
    const tokenUpdatedObserver = Observer.useObserver(EVENTS.ACCESS_TOKEN_UPDATED);

    useEffect(() => {
        if (accessToken) {
            const expTime = getTokenExpirationTime(accessToken);
            startTokenRefresh(expTime);
        }

        // clear interval when leaving the app
        return () => clearIntervalCustom(refreshInterval);
    }, [tokenUpdatedObserver]);

    useEffect(() => {
        if (!refreshInterval) {
            setAccessToken(LocalStorage.get("access_token") || "");
        }
    }, [refreshInterval]);

    const clearIntervalCustom = (intervalId: any) => {
        if (intervalId) {
            clearInterval(intervalId);
        }
        setRefreshInterval(null);// after stopping interval, you still need to delete the reference
    }

    const startTokenRefresh = (expTime: number) => {
        const refreshThreshold = 60 * 1000; // Refresh 1 minute before expiry
        const refreshTime = expTime - refreshThreshold;
        // const refreshTime = Date.now() + 30 * 1000; // Refresh after 30 seconds - for testing

        const interval = setTimeout(async () => {
            await AuthHandler.refreshToken();
        }, refreshTime - Date.now());
        setRefreshInterval(interval);
    }

};